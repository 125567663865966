<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
var echarts = require("echarts");
require("echarts/theme/macarons");
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "200px",
    },
    chartData: {
      type: Array,
      required: true,
      default: () => [],
    },
    isCanClick: {
      type: Boolean,
      default: true,
    },
    stackNormal: {
      type: Boolean,
      default: true
    },
    color: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: null,
      interval: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        clearInterval(this.interval);
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(chartData = []) {
      const that = this;
      const label = chartData.map(item => item.Key);
      const data = [];
      chartData.map((el) => {
        data.push(el.Value);
      });

      var series = [];
      if (chartData.length && chartData[0].Children && chartData[0].Children.length) {
        chartData[0].Children.map((item, index) => {
          var d = chartData.map(item2 => item2.Children[index].Value)

          series.push({
            name: item.Key,
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: function (p) {
                return p.data.toFixed(0);
              }
            },
            emphasis: {
              focus: "series",
            },
            barWidth: 40,
            data: d,
          })
        })
      }
      var option = {
        color: this.color,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "20",
          right: "20",
          bottom: "10",
          containLabel: true,
        },
        [this.stackNormal ? 'xAxis' : 'yAxis']: {
          type: "value",
          splitLine: {
            show: false
          },
          splitArea: {
            show: false
          }
        },
        [this.stackNormal ? 'yAxis' : 'xAxis']: {
          type: "category",
          data: label,
          axisLabel: {
            rotate: this.stackNormal ? 270 : 0,
            interval: 0,
            width: 100,
            overflow: 'truncate',
            ellipsis: '...',
            color: '#fff'
          },
          splitLine: {
            show: false
          }
        },
        series: series
      };
      if (this.chart) {
        that.chart.setOption(option);
      }
    },
    handleClick(params) { },
  },
};
</script>
