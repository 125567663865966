<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
var echarts = require("echarts");
require("echarts/theme/macarons");
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "200px",
    },
    color: {
      type: String,
      default: "#5B8FF9",
    },
    chartData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      chart: null,
      interval: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(chartData = []) {
      const label = chartData.map((el) => el.Key);
      const data = chartData.map((el) => el.Value);
      const that = this;
      var option = {
        xAxis: {
          data: label,
          // boundaryGap: false,
          axisTick: {
            show: true,
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            color: "#79A4FF",
            interval: 0,
            rotate: 0,
            lineHeight: 20,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#79A4FF",
            },
          },
        },
        grid: {
          left: 20,
          right: 20,
          bottom: 20,
          top: 20,
          height: "90%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          padding: [5, 10],
          textStyle: {
            color: "#fff",
          },
        },
        yAxis: {
          axisTick: {
            show: true,
          },
          axisLabel: {
            show: true,
            color: "#79A4FF",
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#79A4FF",
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ["transparent"],
            },
          },
        },
        // dataZoom: [// 滑动条
        //   {
        //     xAxisIndex: 0, // 这里是从X轴的0刻度开始
        //     show: false, // 是否显示滑动条，不影响使用
        //     type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
        //     startValue: 0, // 从头开始。
        //     endValue: 4 // 一次性展示7个。
        //   }
        // ],
        series: [
          {
            itemStyle: {
              color: "#5CC7CF",
              lineStyle: {
                color: "#5CC7CF",
                width: 2,
              },
            },
            label: {
              show: true,
              color: "#5CC7CF",
              position: [-6, -14],
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(0, 255, 255, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(2, 13, 68, 0)",
                },
              ]),
            },
            symbol: "circle",
            symbolSize: 8,
            smooth: false,
            type: "line",
            data,
          },
        ],
      };
      if (this.chart) {
        that.chart.setOption(option);
      }
      // this.interval = setInterval(() => {
      //   // 每次向后滚动一个，最后一个从头开始。
      //   if (option.dataZoom[0].endValue === label.length) {
      //     option.dataZoom[0].endValue = 4
      //     option.dataZoom[0].startValue = 0
      //   } else {
      //     option.dataZoom[0].endValue += 1
      //     option.dataZoom[0].startValue += 1
      //   }
      //   this.chart.setOption(option)
      // }, 3000)
    },
  },
};
</script>
