<template>
  <div v-loading="loading" class="div-content1" :style="{
      transformOrigin: 'center top',
      transform: `scale(${scalseNum},${scalseNum})`,
      '-webkit-transform': `scale(${scalseNum},${scalseNum})`,
      '-moz-transform': `scale(${scalseNum},${scalseNum})`,
      '-o-transform': `scale(${scalseNum},${scalseNum})`,
      '-ms-transform': `scale(${scalseNum},${scalseNum})`,
    }" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(7, 6, 43, 0.5)">
    <!-- <ChartModel title="总体进度">
      <template slot="chart">
        <div class="pie"></div>
      </template>
    </ChartModel> -->
    <div class="u-flex-col u-col-center">
      <div class="head">
        <div class="head_left"></div>
        <div class="head_center">广轻商贸产业学院数字引擎V1.0</div>
        <div class="head_right">{{ timeData }}</div>
      </div>
      <div class="visual_box">
        <div class="visual_box_left">
          <div class="annulus_bg">
            <div class="title">学生满意度</div>
            <StackedFigure height="900px" width="100%" :chartData="studentEvaluate" />
            <!-- <stackedFigure, height="900px" width="100%" /> -->
            <!-- <ChartModel title="项目营收增长">
              <template slot="chart">
                <LineChart
                  height="260px"
                  width="100%"
                  :chartData="ProjectClassSaleRatioByMonth"
                />
              </template>
            </ChartModel>

            <div class="project_rankingList">
              <div class="title">项目综合排行榜</div>
              <div
                class="listBox"
                v-for="(item, index) in ProjectClassSale"
                :key="index"
              >
                <img :src="item.Icon" class="img" alt="" />
                <div class="list_right">
                  <div class="name">{{ item.Name }}</div>
                  <div class="text">
                    <span class="leftTxt mt-9 mr-8">订单</span
                    ><span class="number mr-75">{{ item.OrderCount }}</span>
                    <span class="leftTxt mt-9 mr-8">营收</span
                    ><span class="number">{{ item.Turnover }}</span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- <ChartModel title="整体项目收益情况">
            <template slot="chart">
              <LineChart :chartData="[
                  { Key: '收益', Value: 10 },
                  { Key: '收益', Value: 30 },
                  { Key: '收益', Value: 30 },
                  { Key: '收益', Value: 30 },
                  { Key: '收益', Value: 80 },
                ]" height="230px" />
            </template>
          </ChartModel>
          <ChartModel title="项目订单数量情况">
            <template slot="chart">
              <ArrayBar1 height="250px" width="100%" :chartData="[]" />
            </template>
          </ChartModel> -->
        </div>
        <div class="visual_box_center">
          <div class="allChart">
            <div class="chartList" v-for="(item, index) in monitoringList" @click="goDetail(item.Path)" :key="index">
              <img :src="item.Icon" class="chartImg" alt="" />
              <div class="chart_right">
                <div class="name">{{ item.Titles }}</div>
                <div class="quantity">
                  <span class="number">{{ item.Number }}</span>{{ item.Unit }}
                </div>
              </div>
            </div>
          </div>
          <div class="histogram">
            <ChartModel title="项目班参与学生">
              <template slot="chart">
                <ArrayBar1 height="345px" width="100%" :chartData="ProjectClassTeacherStudent" />
              </template>
            </ChartModel>
          </div>
          <div class="pieBox">
            <ChartModel title="教师满意度">
              <template slot="chart">
                <StackedFigure :color="['#f6bd16','#e8684a']" height="300px" width="100%" :stack-normal="false" :chartData="teacherEvaluate" />
                <!-- <ArrayBar1 height="345px" width="100%" :chartData="EnterpriseProject" /> -->
              </template>
            </ChartModel>
          </div>
        </div>
        <div class="visual_box_right">
          <ChartModel title="学生考勤">
            <template slot="chart">
              <RingCharts height="250px" width="370px" :chartData="StudentSign" />
            </template>
          </ChartModel>
          <div class="month_star">
            <div class="title">月度之星</div>
            <div v-if="studentStarList.length>0">
              <div class="starList" v-for="(item, i) in studentStarList" :key="i">
                <div class="my_img">
                  <img :src="item.Avatar" class="img" alt="" />
                </div>
                <div class="my_name">
                  <div class="name">
                    <span class="label mr-6">姓名：</span>
                    <span class="num">{{ item.StudentName }}</span>
                  </div>
                  <div class="project">
                    <span class="label mr-6">项目：</span><span class="num2">{{ item.ProjectClassName }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="color:white;font-size:24px;text-align:center;line-height: 400px;">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formartDate } from "@/utils/tools.js";
// import "@/styles/visual-analysis.scss";
import ChartModel from "./components/ChartModel1.vue";
import StackedFigure from "./components/StackedFigure.vue";
import PieChart from "./components/PieChart1.vue"; //整体任务完成度
import LineChart from "./components/LineChart.vue"; //整体项目收益情况
import ArrayBar1 from "./components/ArrayBar1.vue";
import StudentBarChart from "./components/StudentBarChart.vue";
import StaffStudentChart from "./components/StaffStudentChart.vue";
import RingCharts from "./components/RingCharts.vue";
import {
  getHomeStatistics,
  getProjectClassTeacherStudent,
  getProjectClassSale,
  getProjectClassSaleRatioByMonth,
  getTeacherStudentRatio,
  getStudentClassRatio,
  getEnterpriseProject,
  getStudentSign,
  getStudentSignRatio,
  getStarList,
  getStudentEvaluate,
  getTeacherEvaluate,
} from "@/api/visualAnalysis";
export default {
  name: "analysis",
  props: {},
  components: {
    ChartModel,
    PieChart,
    LineChart,
    ArrayBar1,
    StudentBarChart,
    StaffStudentChart,
    RingCharts,
    StackedFigure,
  },
  data() {
    return {
      loading: false,
      scalseNum: null,
      timeData: null,
      HomeStatistics: {
        ProjectClassCount: "-",
        EnterpriseCount: "-",
        StudentCount: "-",
      },
      TeacherStudentRatio: [],
      ProjectClassTeacherStudent: [],
      EnterpriseProject: [],
      ProjectClassSale: [],
      ProjectClassSaleRatioByMonth: [],
      StudentSign: [],
      StudentClassRatio: [],
      studentStarList: [],
      studentEvaluate: [],
      teacherEvaluate: [],
    };
  },
  computed: {
    monitoringList: ({ HomeStatistics = {} }) => [
      {
        Icon: require("@/assets/images/analysis/icon/object_icon.png"),
        Titles: "运行项目",
        Number: HomeStatistics.ProjectClassCount,
        Unit: "个",
        Path: "/visual_analysis/projectCase",
      },
      {
        Icon: require("@/assets/images/analysis/icon/enterprise_icon.png"),
        Titles: "参与企业",
        Number: HomeStatistics.EnterpriseCount,
        Unit: "家",
        Path: "/visual_analysis/enterpriseInfo",
      },
      {
        Icon: require("@/assets/images/analysis/icon/student _icon.png"),
        Titles: "参与学生",
        Number: HomeStatistics.StudentCount,
        Unit: "名",
        Path: "/visual_analysis/studentInfo",
      },
    ],
  },
  mounted() {
    setInterval(() => {
      this.getTimeData();
    }, 1000);
    this.resize_window();
    window.addEventListener("resize", () => {
      this.resize_window();
    });
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.timeData);
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.resize_window();
    });
  },
  methods: {
    getTimeData() {
      this.timeData = formartDate(new Date(), "allweek");
    },
    resize_window() {
      const w_height = Number(document.documentElement.clientHeight / 1080);
      var w_width = Number(document.documentElement.clientWidth / 1920);
      var clineRate =
        document.documentElement.clientWidth /
        document.documentElement.clientHeight;
      this.scalseNum = clineRate < 1920 / 1080 ? w_width : w_height;
    },
    goDetail(path) {
      // this.$router.push(path)
    },
    async init() {
      getHomeStatistics().then(({ Result = {} }) => {
        this.HomeStatistics = { ...this.HomeStatistics, ...Result };
      });
      getTeacherStudentRatio().then(({ Result = [] }) => {
        this.TeacherStudentRatio = Result;
      });
      getProjectClassTeacherStudent().then(({ Result = [] }) => {
        this.ProjectClassTeacherStudent = Result.map((item) => ({
          Key: item.Name,
          Value: item.StudentCount,
        }));
      });
      getEnterpriseProject().then(({ Result = [] }) => {
        var a = [3, 2, 1, 0, 0, 0, 0];
        this.EnterpriseProject = Result.map((item, index) => ({
          Key: item.Key,
          Value: item.Value + a[index],
        }));
      });
      getProjectClassSale().then(({ Result = [] }) => {
        Result = Result.slice(0, 5);
        this.ProjectClassSale = Result.map((item, index) => ({
          Icon: require(`@/assets/images/analysis/tag/${index + 1}.png`),
          Name: item.Name,
          OrderCount: item.OrderCount,
          Turnover: item.Turnover,
        }));
      });
      getProjectClassSaleRatioByMonth().then(({ Result = [] }) => {
        this.ProjectClassSaleRatioByMonth = Result;
      });
      getStudentSign().then(({ Result = [] }) => {
        this.StudentSign = Result;
      });
      getStudentClassRatio().then(({ Result = [] }) => {
        this.StudentClassRatio = Result;
      });
      getStarList().then(({ Result = [] }) => {
        this.studentStarList = Result;
      });
      getStudentEvaluate().then(({ Result = [] }) => {
        this.studentEvaluate = Result;
      });
      getTeacherEvaluate().then(({ Result = [] }) => {
        this.teacherEvaluate = Result;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.div-content1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  // padding: 0px 40px 35px 40px;
  // background-color: pink;
  .visual_box {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0 40px;
  }
  .annulus_bg {
    background: url('~@/assets/images/analysis/left_big_bg.png') no-repeat;
    background-size: 100% 100%;
    z-index: 999;
    width: 100%;
    height: 100%;
  }
  .head {
    width: 1920px;
    height: 88px;
    background: url('~@/assets/images/analysis/head.png') no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: space-between;
    // overflow: hidden;
    .head_left {
      width: 97px;
      height: 48px;
      // object-fit: cover;
      // cursor: pointer;
      margin: 20px 129px 0 38px;
      // background: url("~@/assets/images/analysis/project/return_nor.png")
      //   no-repeat;
      // background-size: 100% 100%;
      // &:hover {
      //   background: url("~@/assets/images/analysis/project/return_hov.png")
      //     no-repeat;
      //   background-size: 100% 100%;
      // }
    }
    .head_center {
      background-size: 100%;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 40px;
      padding-top: 18px;
      text-align: center;
      text-shadow: 0px 2px 8px rgba(7, 15, 64, 0.7);
    }
    .head_right {
      width: 226px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #389df6;
      line-height: 22px;
      margin: 12px 43px 0 0;
    }
  }
  .visual_box_left {
    width: 442px;
    height: 957px;
    box-sizing: border-box;
    .title {
      width: 100%;
      height: 56px;
      text-align: center;
      line-height: 56px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    & /deep/ .chart-container:nth-child(n + 3) {
      margin-bottom: 0;
    }
    .project_rankingList {
      width: 442px;
      height: 629px;
      background: url('~@/assets/images/analysis/project/left_bg.png') no-repeat;
      background-size: 100% 100%;
      .title {
        width: 100%;
        height: 33px;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin: 12px 0 11px;
      }
      .listBox {
        width: 100%;
        height: 114px;
        display: flex;
        align-items: center;
        box-shadow: 0px -1px 0px 0px #26388d;
        .img {
          width: 28px;
          height: 28px;
          object-fit: cover;
          margin: 0 14px;
        }
        .list_right {
          overflow: hidden;
          white-space: nowrap;
          .name {
            font-size: 21px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 29px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text {
            .leftTxt {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #557df7;
              line-height: 20px;
            }
            .number {
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  .visual_box_center {
    width: 908px;
    height: 957px;
    box-sizing: border-box;
    margin: 0 24px;
    .allChart {
      width: 100%;
      height: 128px;
      display: flex;
      box-sizing: border-box;
      .chartList {
        width: 286px;
        height: 96px;
        display: flex;
        margin: 32px 0 15px 0;
        padding: 8px 0 7px 0px;
        margin-right: 40px;
        box-sizing: border-box;
        user-select: none;
        cursor: pointer;
        &:hover {
          background: url('~@/assets/images/analysis/top_bg_hover.png')
            no-repeat;
          background-size: 100% 100%;
        }
        &:nth-child(3) {
          margin-right: 0;
        }
        .chartImg {
          width: 64px;
          height: 64px;
          margin: 9px 8px 12px 20px;
        }
        .chart_right {
          .name {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 25px;
          }
          .quantity {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            .number {
              font-size: 48px;
              font-family: DIN-Medium, DIN;
              font-weight: 500;
              color: #00ffff;
              line-height: 58px;
              text-shadow: 0px 2px 8px rgba(0, 255, 255, 0.39);
            }
          }
        }
      }
    }
    .salesVolume {
      width: 100%;
      height: 132px;
      display: flex;
      .salesVolume_left {
        &:nth-child(2) {
          margin-right: 0;
        }
        width: 442px;
        height: 132px;
        background: #111a55;
        box-shadow: -4px 0px 0px 0px rgba(56, 157, 246, 0.3);
        margin-right: 24px;
        padding: 24px;
        box-sizing: border-box;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          .quantity_txt {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 25px;
          }
          .quantity_info {
            color: #7291ee;
            margin-left: 8px;
          }
          .quantity_proportion {
            color: #5dd561;
            margin-left: 6px;
          }
          .icon {
            margin-left: 7px;
            width: 10px;
            height: 12px;
            object-fit: cover;
          }
        }
        .quantity_number {
          margin-top: 1px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          .number {
            font-size: 48px;
            font-family: DIN-Medium, DIN;
            font-weight: 500;
            line-height: 58px;
            background-image: linear-gradient(180deg, #fd951d 0%, #ff6010 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            // color: transparent;
          }
        }
      }
    }
    .histogram {
      width: 100%;
      height: 400px;
      margin-top: 24px;
      margin-bottom: 23px;
      box-sizing: border-box;
      /deep/ .chart-container {
        width: 100%;
        height: 100%;
        background: url('~@/assets/images/analysis/project/center_bg.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
    .pieBox {
      width: 100%;
      height: 384px;
      box-sizing: border-box;
      /deep/ .chart-container {
        width: 100%;
        height: 100%;
        background: url('~@/assets/images/analysis/project/center_bg.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .visual_box_right {
    width: 442px;
    height: 957px;
    box-sizing: border-box;
    .month_star {
      width: 442px;
      height: 629px;
      background: url('~@/assets/images/analysis/project/left_bg.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      .title {
        width: 100%;
        height: 56px;
        text-align: center;
        line-height: 56px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .starList {
        width: 100%;
        height: 190px;
        padding: 20px 24px;
        box-sizing: border-box;
        display: flex;
        .my_img {
          margin-right: 30px;
          .img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .my_name {
          height: 150px;
          padding: 30px 0;
          box-sizing: border-box;
          .label {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #557df7;
          }
          .num {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
          }
          .num2 {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
          }
          .name {
            margin-bottom: 20px;
            box-sizing: border-box;
          }
        }
      }
    }
    & /deep/ .chart-container:nth-child(n + 3) {
      margin-bottom: 0;
    }
  }
}
.middle {
  .total {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .total-item {
      display: flex;
      align-items: center;
      .icon {
        width: 64px;
        height: 56px;
        margin-right: 24px;
        background-image: url('~@/assets/images/analysis/head.png');
        background-repeat: no-repeat;
      }
      .department {
        background-image: url('~@/assets/images/analysis/head.png');
        background-size: cover;
      }
      .text {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
      }
      .number {
        font-size: 54px;
        font-family: DicotMedium;
        font-weight: 400;
        color: #28ffac;
        text-shadow: 0px 4px 12px rgba(0, 223, 255, 0.3);
        background: linear-gradient(0deg, #0049fe 0%, #00ffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .unit {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
.bar-chart {
  box-sizing: border-box;
  padding: 0 40px;
  .bar-title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .bar-number {
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #00ffff;
  }
}
>>> .el-radio-button__inner {
  width: 100px;
  height: 32px;
  background: rgba(5, 11, 29, 0);
  border: 1px solid #296aee;
  color: #fff;
}
.nightingale {
  /deep/ .chart-box {
    padding: 0;
  }
}
.select-text {
  color: #fff;
}
/deep/ .el-select {
  .el-input__inner {
    background-color: rgba(33, 83, 174, 0.4);
    border: 1px solid #2153ae;
    color: #fff;
    &:hover {
      border: 1px solid #2153ae;
    }
  }
  .el-select__caret {
    color: #169aff !important ;
  }
  .el-select-dropdown__empty {
    background-color: #2153ae;
    color: #fff;
  }
  .el-popper[x-placement^='bottom'] .popper__arrow::after {
    border-bottom-color: #2153ae;
  }
  .el-select-dropdown {
    border-color: #020d44;
  }
  .el-select__caret {
    color: #557df7;
  }
  .el-select-dropdown__list,
  .el-select-dropdown__item {
    background-color: rgba(33, 83, 174, 1);
    color: #fff;
  }
  .el-select-dropdown__item.selected {
    color: #389df6;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #389df6;
    color: #fff;
  }
  .el-select-dropdown .el-popper {
    border: 1px solid #2153ae !important;
  }
}
</style>
