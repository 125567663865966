import request from "@/utils/request";
const controller = "/VisualAnalysis";

const getHomeStatistics = _ =>
  request({
    url: `${controller}/GetHomeStatistics`,
    method: "get"
  });
// 种类
const getProjectClassTeacherStudent = _ =>
  request({
    url: `${controller}/GetProjectClassTeacherStudent`,
    method: "get"
  });

const getProjectClassSale = _ =>
  request({
    url: `${controller}/GetProjectClassSale`,
    method: "get"
  });

const getProjectClassSaleRatioByMonth = _ =>
  request({
    url: `${controller}/GetProjectClassSaleRatioByMonth`,
    method: "get"
  });

const getTeacherStudentRatio = _ =>
  request({
    url: `${controller}/GetTeacherStudentRatio`,
    method: "get"
  });

const getStudentClassRatio = _ =>
  request({
    url: `${controller}/GetStudentClassRatio`,
    method: "get"
  });

const getEnterpriseProject = _ =>
  request({
    url: `${controller}/GetEnterpriseProject`,
    method: "get"
  });

const getStudentSign = _ =>
  request({
    url: `${controller}/GetStudentSign`,
    method: "get"
  });
const getStudentSignRatio = _ =>
  request({
    url: `${controller}/GetStudentSignRatio`,
    method: "get"
  });

const getStarList = _ =>
  request({
    url: `${controller}/GetMonthStar`,
    method: "get"
  });

const getStudentEvaluate = _ =>
  request({
    url: `${controller}/GetStudentEvaluate`,
    method: "get"
  });

const getTeacherEvaluate = _ =>
  request({
    url: `${controller}/GetTeacherEvaluate`,
    method: "get",
    params: _
  });

export {
  getHomeStatistics,
  getProjectClassTeacherStudent,
  getProjectClassSale,
  getProjectClassSaleRatioByMonth,
  getTeacherStudentRatio,
  getStudentClassRatio,
  getEnterpriseProject,
  getStudentSign,
  getStudentSignRatio,
  getStarList,
  getStudentEvaluate,
  getTeacherEvaluate
};
