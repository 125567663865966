<template>
  <div :class="className" :style="{ width: width, height: height }"></div>
</template>

<script>
var echarts = require("echarts");
require("echarts/theme/macarons");
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "303px",
    },
    chartData: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.chartInit(val);
      },
    },
  },
  mounted() {
    this.chart = echarts.init(this.$el, "macarons");
    this.$nextTick(() => {
      this.chartInit();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    chartInit() {
      this.setOptions(this.chartData);
    },
    setOptions(chartData = []) {
      var chartXdata = [];
      chartData.forEach((el, i) => {
        chartXdata.push({ name: el.Key, value: el.Value });
      });
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          top: "20%",
          right: 0,
          orient: "vertical",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              color: "#fff",
              position: "inner",
              formatter: "{d}%",
              // formatter: res => {
              //   return res.value;
              // }
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "40",
            //     fontWeight: "bold"
            //   }
            // },

            color: [
              "#169AFF",
              "#5AD8A6",
              "#5D7092",
              "#F6BD16",
              "#E8684A",
              "#6DC8EC",
              "#9270CA",
              "#FF9D4D",
            ],

            labelLine: {
              show: false,
            },
            data: chartXdata,
          },
        ],
      });
    },
  },
};
</script>

<style scoped></style>
