<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
var echarts = require("echarts");
require("echarts/theme/macarons"); // echarts theme
// const animationDuration = 6000

export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    materialItem: {
      type: Array,
      default: () => [],
    },
    notSubmit: {
      type: Array,
      default: () => [],
    },
    totalProgress: {
      type: Number,
      default: 0,
    },
    hadSubmit: {
      type: Array,
      default: () => [],
    },
    yAxis: {
      type: Object,
      default: () => ({
        type: "value",
        name: "",
      }),
    },
    markWord: {
      type: String,
      default: "",
    },
    formatter: {
      type: String,
      default: "{b0}: {c0}",
    },
    monitoring: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chart: null,
    };
  },

  watch: {
    materialItem() {
      // this.initChart();
    },
    chartData: {
      deep: true,
      handler(val) {
        this.initChart(val);
      },
    },
  },
  mounted() {
    this.chart = echarts.init(this.$el, "macarons");
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.setOption(this.chartData);
    },
    setOption(chartData) {
      var option = {
        series: [
          {
            name: "",
            type: "pie",
            radius: "80%",
            center: ["50%", "50%"],
            data: chartData.map((el) => ({
              name: el.Key,
              value: el.Value,
            })),
            // roseType: "radius",
            // color: ["#0024FF", "#169AFF"],
            color: ["#169AFF", "#e8684a"],
            label: {
              show: true,
              fontSize: 16,
              formatter: "{b}: ({d}%)",
              color: "#FFFF",
              // formatter: function (params) {
              //   const res =
              //     "{a|" + params.name + "}\n" + "{b|" + params.value + "%}";
              //   return res;
              // },
              // rich: {
              //   a: {
              //     color: "#fff",
              //     fontSize: 12
              //   },
              //   b: {
              //     color: "#00CBE9",
              //     fontSize: 16
              //   }
              // }
            },
            labelLine: {
              smooth: 0,
              length: 0,
              length2: 0,
            },

            itemStyle: {
              shadowBlur: 0,
              shadowColor: "#0024FF",
            },

            animationType: "scale",
            // animationEasing: "elasticOut",
            animationDelay: function(idx) {
              return Math.random() * 200;
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>
