<template>
  <div :style="{ width: width, height: height }"></div>
</template>
<script>
var echarts = require("echarts");
require("echarts/theme/macarons");
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "240px",
    },
    percent: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(val = 0) {
      this.chart.setOption({
        title: {
          text: "完成度",
          left: "center",
          top: "46%",
          textStyle: {
            color: "#fff",
            fontWeight: 400,
            lineHeight: 20,
            fontSize: 14,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["80%", "60%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: true,
            grid: {
              top: "5%",
              left: "0%",
              right: "0%",
              bottom: "0%",
              height: "90%",
              containLabel: true,
            },
            data: [
              {
                name: "达标率",
                value: val,
                itemStyle: {
                  // borderRadius: 20,
                  color: {
                    type: "linear",
                    x: 1,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#00CCFF", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FF4800", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
                label: {
                  show: true, // 单独显示该数据项
                  formatter: "{d}%",
                  color: "#00FFFF",
                  fontSize: 40,
                },
              },
              {
                value: 100 - val,
                itemStyle: {
                  color: "#152472",
                  emphasis: { color: "#152472" },
                },
              },
            ],
            label: {
              // 默认不显示数据
              show: false,
              position: "center",
            },
          },
        ],
      });
    },
  },
};
</script>
<style scoped></style>
